<template>
  <v-layout>
    <div id="div_data">
      <span>hi</span>
      <h2>bye</h2>
    </div>
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
    <v-btn @click="copyDiv">Copy</v-btn>
    <div v-html="copy">{{copy}}</div>
   <!-- <v-file-input
    placeholder="Pick an avatar (filesize:2Mb)"
    prepend-icon="mdi-camera"
    label="Avatar"
    show-size
    v-model="file"
  ></v-file-input>
  <v-text-field
            label="Regular"
            placeholder="Placeholder"
            v-model="fname"
          ></v-text-field>
  <button @click="submitFile">Submit</button> -->
</v-layout>
</template>

<script>
import axios from 'axios'
 import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  export default {
    /*
      Defines the data used by the component
    */
    data(){
      return {
        file:null,
        fname:'',
       div_data:null,
       copy:null,
       editor: ClassicEditor,
        editorData: '<p>Content of the editor.</p>',
        editorConfig: {
            // The configuration of the editor.
        }
      }
    },

    methods: {
copyDiv(){
  this.copy = this.editorData//document.getElementById("div_data").innerHTML
},
      submitFile(){
            
            let formData = new FormData();
            console.log(this.file.size)
            formData.append("newlogo", this.file)
            formData.append("fname", this.fname)
            console.log(formData)
            axios.post( 'tryNError/fileUpload',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(){
          console.log('SUCCESS!!');
        })
        .catch(function(){
          console.log('FAILURE!!');
        });
        //     }
      },

      /*
        Handles a change on the file upload
      */

    }
  }
</script>